import logo from './static/images/Logo.png';
import './static/css/Nav.css';
import Landing from './containers/Landing';

function App() {
  return (
    <div className="App">
      <div className="container" id="Navigation">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
          <img src={logo} height="30" width="30" className="navbar-brand nav-logo" alt=""></img>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto">
              
              <li className="nav-item">
                <a className="nav-link" href="#About">About Us</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#AreasOfWork">Areas Of Work</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#Commodities">Commodities</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#Message">Our Message</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Landing className="landing"></Landing>
    </div>
  );
}

export default App;
