import React, { Component } from 'react';
import '../static/css/Landing.css';
import background from '../static/images/background.jpg';
import logo from '../static/images/Logo.png';
import value from '../static/images/value chain analysis.jpg';
import trading from '../static/images/business modelling.jpg';
import due from '../static/images/due diligence.jpg';
import partnership from '../static/images/partnership.jpg';
import management from '../static/images/risk management.jpg';
import agriculture from '../static/images/agriculture.jpg';
import metals from '../static/images/metals.jpg';
import minerals from '../static/images/minerals.jpg';
import mining from '../static/images/Mining.jpg';


export default class Landing extends Component {
    render() {
        return (
            <div className="container-fluid" id="Landing">
                <div className="row">
                    <div className="container-fluid" id="Header">
                        <img src={background} id="background-image" alt="background" />
                        <img src={logo} id="logo-image" alt="logo" />
                        <h1 id="branding">BVM Group Hong Kong</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid" id="About">
                        <h1>About Us</h1>
                        <br />
                        <h6>
                        BVM Group is a virtual desk that has been created for holistic way of approaching commodity trading Globally.
                        <br />
                        <br />
                        The aim of BVM is to conduct due diligence, risk management for its end buyer and giving end based solutions 
                        in business.
                        <br />
                        <br />
                        BVM envisaged on proper selection of suppliers which is a key weapon as we give line of confidence and 
                        success road map to our end buyers and end suppliers  with trust , honesty , value , integrity and professional 
                        attitude.

                        <br />
                        <br />
                        Operated by M-JAY Grand Enterprises Hong Kong.
                        <br />
                        <a href="https://mjaygrand.com" class="btn btn-primary" role="button">M-JAY Grand</a>
                        </h6>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid" id="AreasOfWork">
                        <h1>Areas Of Work</h1>
                        <br />
                        <br />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-2">
                                    <img src={value} alt="value chain analysis"/>
                                    <br />
                                    <h3>Value Chain Analysis</h3>
                                </div>
                                <div className="col-lg-2">
                                    <img src={trading} alt="trading business modelling"/>
                                    <br />
                                    <h3>Trading Business Modelling</h3>
                                </div>
                                <div className="col-lg-2">
                                    <img src={due} alt="due diligence"/>
                                    <br />
                                    <h3>Due Diligence</h3>
                                </div>
                                <div className="col-lg-2">
                                    <img src={partnership} alt="long-term partnerships"/>
                                    <br />
                                    <h3>Long Term Partnerships</h3>
                                </div>
                                <div className="col-lg-2">
                                    <img src={management} alt="risk management"/>
                                    <br />
                                    <h3>Risk Management</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid" id="Commodities">
                        <h1>Commodities</h1>
                        <br />
                        <br />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-3">
                                    <img src={agriculture} alt="Agriculture" id="agricultureImage" />
                                    <h3>Agro Products</h3>
                                </div>
                                <div className="col-lg-3">
                                    <img src={metals} alt="metals"/>
                                    <h3>Metals</h3>
                                </div>
                                <div className="col-lg-3">
                                    <img src={minerals} alt="minerals" id="mineralsImage"/>
                                    <h3>Minerals</h3>
                                </div>
                                <div className="col-lg-3">
                                    <img src={mining} alt="mining" />
                                    <h3>Mining</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid" id="Message">
                        <h1>Our Message</h1>
                        <br />
                        <h6>We encourage every supplier to communicate with us to perform globally.</h6>
                        <br />
                        <hr />
                        <br />
                        <h6>We respect buyers philosophy and we try to give end solution.</h6>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid" id="Contact">
                        <h1>Contact Us</h1>
                        <p className="text-muted">Under Development</p>
                        <h3>Please send an email to vimal@mjaygrand.com</h3>
                        <h3>Or bvm@mjaygrand.com</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid" id="Footer">
                        <div className="row">
                            <div className="address col-sm-4">
                                <h6>
                                    Address: <br/>
                                    Room 301 Kam On Building,
                                    <br/>
                                    176A Queen's Road Central
                                    <br />
                                    Hong Kong
                                </h6>
                            </div>
                            
                            <div className="email col-sm-4">
                                <h6>
                                    Email: <br/>
                                    vimal@mjaygrand.com <br/>
                                    bvm@mjaygrand.com <br/>
                                </h6>
                            </div>

                            <div className="contactNo col-sm-4">
                                <h6>
                                    HK Mobile No./Whatsapp : +852- 9746 8144 <br />
                                    Telephone : +852-2547 1140 <br />
                                </h6>
                            </div>
                        </div>
                        <h6>Copyright &copy; BVM Group 2021</h6>
                    </div>
                </div>
            </div>
        )
    }
}
